export function getHref(route?: string | null, slug?: string | null, url?: string | null): string {
  // Ensure we're working with strings, not null or undefined
  const safeRoute = typeof route === 'string' ? route : '';
  const safeSlug = typeof slug === 'string' ? slug : '';
  const safeUrl = typeof url === 'string' ? url : '';
  
  // External URL
  if (safeUrl) {
    return safeUrl.startsWith('http') ? safeUrl : `https://${safeUrl}`;
  }

  // Route with slug
  if (safeRoute && safeSlug) {
    return `${safeRoute.startsWith('/') ? '' : '/'}${safeRoute}/${safeSlug}`;
  }

  // Only route
  if (safeRoute) {
    return safeRoute.startsWith('/') ? safeRoute : `/${safeRoute}`;
  }

  // Only slug
  if (safeSlug) {
    return `/${safeSlug}`;
  }

  // Fallback to homepage if no valid link is provided
  return 'null';
}