'use client';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES, MARKS } from '@contentful/rich-text-types';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { ExpandIcon, XIcon } from 'lucide-react';
import Image from 'next/image';
import { Link } from 'next-view-transitions';
import React, { ReactNode, useEffect, useState } from 'react';

import ClientCTAButton from '@/app/components/ui/Buttons/ClientCTAButton';
import LinkButton from '@/app/components/ui/Buttons/LinkButton';
import LinkComponent from '@/app/components/ui/Interactive/Link';
import VideoPlayer from '@/app/components/ui/Video/VideoPlayer';
import { getHref } from '@/app/lib/utils/getHref';
import { paddingClasses } from '@/app/lib/utils/getPaddingClasses';

const backgroundColorClasses: { [key: string]: string | string[] | null } = {
  None: 'bg-tansparent',
  undefined: 'bg-tansparent',
  'Slate Blue': 'bg-SLATE_BLUE',
  'Off Black': 'bg-OFF-BLACK',
} as const;

const noContainerPages: string[] = ['blog', 'resources/threat-briefings', 'legal', 'faq', 'news-and-press'] as const;

export default function RichTextComponent({ content, locationData }: any) {
  const [fullscreenImage, setFullscreenImage] = useState<{ url: string; alt: string; width: number; height: number } | null>(null);

  const {
    richTextComponent: {
      text,
      twoColumnParagraphs,
      centerHeadings,
      justifyParagraphs,
      centerParagraphs,
      backgroundColor,
      spacingTop,
      spacingBottom,
    },
  } = content;

  // Add effect to handle escape key for modal
  useEffect(() => {
    const handleEscapeKey = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && fullscreenImage) {
        setFullscreenImage(null);
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    // Prevent scrolling when modal is open
    if (fullscreenImage) {
      document.body.style.overflow = 'clip';
    }

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
      document.body.style.overflow = '';
    };
  }, [fullscreenImage]);

  const paddingTop = paddingClasses.top?.[spacingTop as keyof typeof paddingClasses.top] || 'pt-1';
  const paddingBottom = paddingClasses.bottom?.[spacingBottom as keyof typeof paddingClasses.bottom] || 'pb-1';

  const renderList = (node: any, ordered: boolean, isNested: boolean = false): React.ReactNode => {
    const Tag: 'ol' | 'ul' = ordered ? 'ol' : 'ul'; // Choose between ordered (ol) or unordered (ul) list

    return (
      <Tag
        className={clsx('col-span-full h-full w-full pb-2 pl-8', {
          'list-decimal': ordered && !isNested, // Use decimal for top-level ordered lists
          'list-disc': !ordered && !isNested, // Use disc for top-level unordered lists
          'list-none': isNested, // No default marker for nested lists (we'll add custom markers)
        })}
      >
        {/* Map through each list item in the content */}
        {node.content.map((item: any, index: number) => renderListItem(item, index, ordered, isNested))}
      </Tag>
    );
  };

  const renderListItem = (node: any, index: number, ordered: boolean, isNested: boolean): React.ReactNode => {
    let marker: string = '';
    if (isNested && ordered) {
      marker = String.fromCharCode(65 + index) + '.';
    }

    const key: string = `${index}-${marker}${node.content[0]?.content[0]?.value?.slice(0, 20) || ''}`;

    return (
      <li
        key={key}
        className={clsx(
          'w-full pb-3 text-RichText-Body font-normal text-WHITE/90 md:text-Body-Large sm:text-Body',
          { 'marker:text-RichText-Body marker:md:text-Body-Large marker:sm:text-[0.9rem]': !isNested || !ordered },
          { 'pt-3 last:pb-0': isNested },
        )}
      >
        {isNested && ordered && <span className="inline-block w-5 sm:w-20px">{marker}</span>}

        {node.content.map((content: any, contentIndex: number) => {
          if (content.nodeType === BLOCKS.PARAGRAPH) {
            return (
              <React.Fragment key={`${key}-paragraph-${contentIndex}`}>
                {content.content.map((item: any, itemIndex: number) => (
                  <React.Fragment key={`${key}-paragraph-${contentIndex}-item-${itemIndex}`}>
                    {item.nodeType === 'hyperlink' ? (
                      <Link
                        href={item.data.uri}
                        target="_blank"
                        className="text-BLUE-400 underline transition-all duration-300 hover:text-BLUE-400/80 focus:text-BLUE-400/80 active:text-BLUE-400/60"
                      >
                        {item.content[0].value}
                      </Link>
                    ) : (
                      item.value
                    )}
                  </React.Fragment>
                ))}
              </React.Fragment>
            );
          } else if (content.nodeType === BLOCKS.OL_LIST || content.nodeType === BLOCKS.UL_LIST) {
            return renderList(content, content.nodeType === BLOCKS.OL_LIST, true);
          }
          return null;
        })}
      </li>
    );
  };

  const handleImageClick = (url: string, alt: string, width: number, height: number) => {
    setFullscreenImage({ url, alt, width, height });
  };

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (_node: any, children: any) => (
        <h1
          className={clsx(
            'col-span-full justify-self-center text-pretty pb-4 pt-6 text-RichText-H1 text-WHITE/90 first:pt-0 md:text-RichText-H2',
          )}
        >
          {children}
        </h1>
      ),
      [BLOCKS.HEADING_2]: (_node: any, children: any) => (
        <h2
          className={clsx('col-span-full text-pretty pb-3 pt-6 text-RichText-H2 text-WHITE/90 first:pt-0 md:text-RichText-H3', {
            'justify-self-center text-center': centerHeadings,
          })}
        >
          {children}
        </h2>
      ),
      [BLOCKS.HEADING_3]: (_node: any, children: any) => (
        <h3
          className={clsx('col-span-full text-pretty pb-3 pt-6 text-RichText-H3 text-WHITE/90 first:pt-0 md:text-RichText-H4', {
            'justify-self-center text-center': centerHeadings,
          })}
        >
          {children}
        </h3>
      ),
      [BLOCKS.HEADING_4]: (_node: any, children: any) => (
        <h4
          className={clsx('col-span-full text-pretty pb-3 pt-6 text-RichText-H4 font-bold text-WHITE/90 first:pt-0 md:text-RichText-H5', {
            'justify-self-center text-center': centerHeadings,
          })}
        >
          {children}
        </h4>
      ),
      [BLOCKS.HEADING_5]: (_node: any, children: any) => (
        <h5
          className={clsx(
            'col-span-full text-pretty pb-3 pt-6 text-RichText-H5 font-bold leading-tight text-WHITE/90 first:pt-0 md:text-RichText-H6',
            { 'justify-self-center text-center': centerHeadings },
          )}
        >
          {children}
        </h5>
      ),
      [BLOCKS.HEADING_6]: (_node: any, children: any) => (
        <h6
          className={clsx('col-span-full text-pretty pb-3 pt-6 text-RichText-H6 font-bold first:pt-0', {
            'justify-self-center text-center': centerHeadings,
          })}
        >
          {children}
        </h6>
      ),
      [BLOCKS.PARAGRAPH]: (_node: any, children: any) => (
        <p
          className={clsx('w-full space-y-5 text-pretty pb-4 text-RichText-Body font-normal text-WHITE/90 last:pb-0', {
            'col-span-6 max-w-[75ch] pr-24 text-justify lg:pr-15 md:pr-4 sm:col-span-full sm:pr-0':
              justifyParagraphs && twoColumnParagraphs,
            'col-span-6 max-w-[75ch] text-balance pr-24 lg:pr-15 md:pr-4 sm:col-span-full sm:pr-0':
              !justifyParagraphs && twoColumnParagraphs,
            'col-span-full max-w-[90ch] pr-0 text-justify': justifyParagraphs && !twoColumnParagraphs,
            'col-span-full max-w-[90ch] pr-0': !justifyParagraphs && !twoColumnParagraphs,
            'col-span-full justify-self-center pr-0 text-center': centerParagraphs && !twoColumnParagraphs,
            'col-span-6 pr-0 text-center': centerParagraphs && twoColumnParagraphs,
          })}
        >
          {children}
        </p>
      ),
      [BLOCKS.QUOTE]: (_node: any, children: any) => (
        <blockquote className="text-balance rounded-md bg-slate-700 px-6 py-6 text-Body-Large font-normal text-WHITE md:text-RichText-Body">
          {children}
        </blockquote>
      ),
      [BLOCKS.HR]: (_node: any) => <hr className="border-t-2 border-WHITE/50 py-4" />,
      [BLOCKS.UL_LIST]: (node: any) => renderList(node, false),
      [BLOCKS.OL_LIST]: (node: any) => renderList(node, true),
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const assetId = node.data.target.sys.id;
        const asset = text.links.assets.block.find((asset: any) => asset.sys.id === assetId);
        const firstAsset = text.links.assets.block[0];

        if (!asset) return null;

        if (asset.url.includes('.mp4')) {
          const aspectRatio = asset.height / asset.width;
          return (
            <article className="col-span-full h-full w-full py-12 md:mt-15">
              <VideoPlayer url={asset.url} aspectRatio={aspectRatio} />
            </article>
          );
        } else if (
          asset.url.includes('.jpg') ||
          asset.url.includes('.jpeg') ||
          asset.url.includes('.png') ||
          asset.url.includes('.gif') ||
          asset.url.includes('.svg') ||
          asset.url.includes('.webp')
        ) {
          const isFirstAsset = asset.sys.id === firstAsset.sys.id;

          // When image is clicked, it opens in a modal by saving the image data to the fullscreenImage state and passing it to the modal
          return (
            <figure className="group relative col-span-full mx-auto my-5 cursor-pointer" style={{ width: isFirstAsset ? '85%' : '90%' }}>
              <div
                onClick={() => handleImageClick(asset.url, asset.description || 'Embedded asset', asset.width || 800, asset.height || 600)}
                onKeyDown={e => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    handleImageClick(asset.url, asset.description || 'Embedded asset', asset.width || 800, asset.height || 600);
                  }
                }}
                role="button"
                tabIndex={0}
                aria-label={`View ${asset.description || 'image'} in full screen`}
                className="relative"
              >
                <Image
                  width={asset.width || 350}
                  height={asset.height || 350}
                  title="Click to view full size"
                  src={asset.url}
                  alt={asset.description || 'Embedded asset'}
                  className="user-select-none h-auto w-full cursor-zoom-in rounded-md transition-transform duration-300 group-hover:scale-[1.01]"
                />
              </div>
              <ExpandIcon
                onClick={() => handleImageClick(asset.url, asset.description || 'Embedded asset', asset.width || 800, asset.height || 600)}
                className="absolute right-2 top-2 h-8 w-8 rounded-full border border-WHITE/40 bg-BLACK/50 p-1 text-WHITE transition-all duration-300 hover:scale-110 hover:text-GREEN-500 focus:ring-2 focus:ring-inset focus:ring-GREEN-500"
              />
              {asset.description && <figcaption className="mt-2 text-Pill text-WHITE/60">{asset.description}</figcaption>}
            </figure>
          );
        }

        return null;
      },
      [INLINES.HYPERLINK]: (node: any, children: any) => {
        const href = node.data.uri;
        return (
          <Link
            href={href}
            target="_blank"
            className="text-BLUE-400 underline transition-all duration-300 hover:text-BLUE-400/80 focus:text-BLUE-400/80 active:text-BLUE-400/60"
          >
            {children}
          </Link>
        );
      },
      [INLINES.EMBEDDED_ENTRY]: (node: any) => {
        const entryId = node.data.target.sys.id;
        const entry = text.links.entries.inline.find((entry: any) => entry.sys.id === entryId);

        if (!entry) return null;

        if (entry.__typename === 'ButtonComponent') {
          const findButtons = text.links.entries.inline.filter((entry: any) => entry.__typename === 'ButtonComponent');
          const isFirstButton = findButtons.indexOf(entry) === 0;
          return (
            <LinkButton
              key={entry.sys.id}
              hrefData={entry}
              label={entry.label}
              buttonType={entry.buttonType}
              className={clsx('mt-4 inline-block', {
                'ml-5': text.links.entries.inline.length > 1 && !isFirstButton,
                'ml-0': isFirstButton,
              })}
            />
          );
        }

        if (entry.__typename === 'FormButton') {
          return <ClientCTAButton buttonData={entry} className="mt-4 inline-block" />;
        }

        if (entry.__typename === 'DetailPage') {
          return (
            <LinkComponent
              key={entry.sys.id}
              href={getHref(entry?.route, entry?.slug, entry?.url)}
              className="ml-1 inline border-none text-BLUE-400 underline"
            >
              Go To Page: {entry.title} {'->'}
            </LinkComponent>
          );
        }
      },
    },
    renderMark: {
      [MARKS.BOLD]: (text: ReactNode) => (
        <strong className={clsx('font-bold', locationData === 'blog' ? 'text-GREEN-500' : '')}>{text}</strong>
      ),
      [MARKS.ITALIC]: (text: ReactNode) => <em className="italic">{text}</em>,
      [MARKS.UNDERLINE]: (text: ReactNode) => <span className="underline">{text}</span>,
      [MARKS.SUPERSCRIPT]: (text: ReactNode) => <sup className="text-xs">{text}</sup>,
      [MARKS.SUBSCRIPT]: (text: ReactNode) => <sub className="text-xs">{text}</sub>,
      [MARKS.STRIKETHROUGH]: (text: ReactNode) => <span className="line-through">{text}</span>,
    },
  };

  return (
    <>
      <section className={`${backgroundColorClasses[backgroundColor]} ${paddingTop} ${paddingBottom}`}>
        <div className={clsx('flex h-full w-full items-center', noContainerPages.includes(locationData) ? '' : 'container')}>
          {twoColumnParagraphs && text && (
            <article className="grid grid-cols-12 gap-x-10 sm:gap-x-5 sm:gap-y-1">
              {documentToReactComponents(text?.json as Document, options)}
            </article>
          )}
          {!twoColumnParagraphs && text && (
            <article className="grid grid-cols-12 gap-x-10 sm:gap-x-5 sm:gap-y-1">
              {documentToReactComponents(text?.json as Document, options)}
            </article>
          )}
        </div>
      </section>

      {/* Fullscreen Image Modal */}
      <AnimatePresence mode="sync">
        {fullscreenImage && (
          <motion.div
            initial={{ opacity: 0, scale: 0.95, transition: { duration: 0.3 } }}
            animate={{ opacity: 1, scale: 1, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, transition: { duration: 0.3, delay: 0.3 } }}
            className="fixed inset-0 z-50 bg-black/80 p-4 backdrop-blur-sm"
            role="dialog"
            aria-modal="true"
            aria-label="Image fullscreen view"
          >
            <div className="flex h-full items-center justify-center">
              <div className="max-h-[90vh] max-w-[90vw]">
                {fullscreenImage && (
                  <motion.button
                    initial={{ opacity: 0, x: 100, y: -100}}
                    animate={{ opacity: 1, x: 0, y: 0, transition: { duration: 0.5 } }}
                    exit={{ opacity: 0, x: 100, y: -100, transition: { duration: 0.3 } }}
                    className="absolute right-4 top-4 z-[60] text-white hover:text-gray-300 focus:outline-none"
                    onClick={() => setFullscreenImage(null)}
                    aria-label="Close fullscreen view"
                    title="Close fullscreen view"
                  >
                    <XIcon className="active:text-PURPLE-500 h-8 w-8 text-GREEN-500 transition-all duration-300 hover:scale-110 focus:ring-2 focus:ring-inset focus:ring-GREEN-500" />
                  </motion.button>
                )}

                {fullscreenImage && (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.95, filter: 'blur(20px)', transition: { duration: 0.3, delay: 0.3 } }}
                    animate={{ opacity: 1, scale: 1, filter: 'blur(0px)', transition: { duration: 0.3, delay: 0.3 } }}
                    exit={{ opacity: 0, scale: 0.95, filter: 'blur(20px)', transition: { duration: 0.3 } }}
                    className="user-select-none h-full w-full"
                  >
                    <Image
                      src={fullscreenImage.url}
                      alt={fullscreenImage.alt}
                      width={fullscreenImage.width}
                      height={fullscreenImage.height}
                      className="max-h-[85vh] max-w-[85vw] object-contain"
                    />
                  </motion.div>
                )}
                <div
                  className="absolute inset-0 cursor-zoom-out"
                  onClick={() => setFullscreenImage(null)}
                  role="button"
                  title="Close fullscreen view"
                  tabIndex={0}
                  onKeyDown={e => {
                    if (e.key === 'Escape' || e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault();
                      setFullscreenImage(null);
                    }
                  }}
                  aria-label="Close fullscreen view"
                />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
