'use client';

import clsx from 'clsx';
import { useRouter } from 'next/navigation';

import Button from '../Interactive/Button';
import LinkButton from './LinkButton';

type ButtonProps = {
  buttonData: any;
  className?: string;
};

export default function ClientCTAButton({ buttonData, className = '' }: ButtonProps): JSX.Element | null {
  const router = useRouter();

  if (!buttonData) return null;

  const isFormButton = buttonData.__typename === 'FormButton';

  const animatedClasses = buttonData.animated
    ? 'text-[14px] relative before:absolute before:inset-0 before:-z-10 before:animate-gradient-shadow before:bg-gradient-to-r before:from-BLUE before:via-PURP before:to-BLUE before:blur-[16px] hover:before:blur-[20px] before:transition-all before:duration-1000 before:scale-95 hover:before:scale-105'
    : '';

  if (isFormButton) {
    const { formType } = buttonData?.formType;
    return (
      <Button
        onClick={() =>
          router.push(
            formType === 'Book a Demo'
              ? '/book-a-demo'
              : formType === 'Threat Briefing Subscription'
                ? '/threat-briefing-subscription'
                : '/',
          )
        }
        buttonType={buttonData.buttonType as 'Primary' | 'Secondary' | 'Tertiary'}
        className={clsx(className, animatedClasses)}
      >
        {buttonData.label}
      </Button>
    );
  }

  return (
    <LinkButton
      hrefData={buttonData}
      buttonType={buttonData.buttonType}
      label={buttonData.label}
      className={clsx(className, animatedClasses)}
    />
  );
}
