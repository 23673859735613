/* eslint-disable jsx-a11y/media-has-caption */
'use client';

import React from 'react';

import { useVideoLoaded } from '@/app/lib/hooks/useVideoLoaded';

import LoadingSpinner from '../Loader/LoadingSpinner';

interface VideoPlayerProps {
  url: string;
  aspectRatio?: number;
}

export default function VideoPlayer({ url, aspectRatio = 16 / 9 }: VideoPlayerProps) {
  const isVideoLoaded = useVideoLoaded(url);

  return (
    <div
      className={`relative aspect-video w-full overflow-hidden rounded-[12px] bg-BLACK-100 shadow-video-purple ${isVideoLoaded ? 'max-h-auto' : ''}`}
      style={{ paddingTop: `${aspectRatio * 100}%` }}
    >
      {!isVideoLoaded && (
        <LoadingSpinner />
      )}
      <video
        loop
        controls
        playsInline
        preload="auto"
        autoPlay={true}
        muted
        className={`h-full w-full object-cover transition-opacity duration-300 ${isVideoLoaded ? 'opacity-100' : 'opacity-0'}`}
      >
        <source src={url} type="video/mp4" />
      </video>
    </div>
  );
}
