'use client';

import { usePathname } from 'next/navigation';
import { Link } from 'next-view-transitions';

import { cn } from '@/app/lib/utils/cn';

interface LinkProps extends React.ComponentPropsWithoutRef<typeof Link> {
  children: React.ReactNode;
  className?: string;
  href: string;
  target?: string;
  tabIndex?: number;
}

function LinkComponent({ children, className, href, target, tabIndex, ...restProps }: LinkProps) {
  const pathname = usePathname();

  // Ensure href is never an empty string
  const safeHref = href || '/';

  return (
    <Link
      className={cn(
        'inline-block border-2 border-slate-700 font-semibold transition-all duration-300 focus-visible:border-WHITE-950 focus-visible:ring-2 focus-visible:ring-WHITE/40 md:text-[1rem] sm:text-xs cursor-pointer',
        className,
      )}
      href={safeHref}
      target={target ?? '_self'}
      tabIndex={tabIndex}
      aria-current={pathname === href ? 'page' : undefined}
      {...restProps}
    >
      {children}
    </Link>
  );
}

export default LinkComponent;
