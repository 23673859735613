import { Link } from 'next-view-transitions';
import React, { ComponentPropsWithoutRef } from 'react';

import { cn } from '@/app/lib/utils/cn';
import { getHref } from '@/app/lib/utils/getHref';

export interface LinkButtonProps {
  route?: string;
  slug?: string;
  url?: string;
  label: string;
  buttonType: 'Primary' | 'Secondary' | 'Tertiary';
  className?: string;
  target?: string;
  hrefData?: any;
}

const buttonStyles = {
  Primary: 'border-2 border-GREEN-500 bg-DEEPGREEN text-WHITE px-5 py-2 rounded-md hover:bg-DEEPGREEN-500 font-[600]',
  Secondary: 'border-2 border-PURP bg-DEEPPURPLE text-WHITE px-5 py-2 rounded-md hover:bg-DEEPPURPLE-500 font-[600]',
  Tertiary: 'text-black hover:opacity-80',
};

const LinkButton: React.FC<LinkButtonProps & ComponentPropsWithoutRef<'button'>> = ({ label, buttonType, className, target, hrefData }) => {
  const baseStyles = 'font-caption text-[11px] transition-colors duration-300';

  const buttonClasses = cn(baseStyles, buttonStyles[buttonType], className);
  const href = getHref(hrefData?.route, hrefData?.slug, hrefData?.url);

  return (
    <Link href={href} target={target} className={buttonClasses} role="link" id={"ABT-Lan-Hero"}>
      {buttonType === 'Tertiary' && (
        <svg className="mr-3 inline-block" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
          <path
            fill="#C1BBF6"
            d="M4.166 11.666v-7.5h1.667v5.833h8.476l-3.291-3.291 1.178-1.179 5.304 5.304-5.304 5.303-1.178-1.178 3.291-3.292H4.166Z"
          />
        </svg>
      )}
      {label}
    </Link>
  );
};

export interface ButtonsProps {
  items: LinkButtonProps[];
  className?: string;
}

export const Buttons: React.FC<ButtonsProps> = ({ items, className }) => {
  return (
    <>
      {items.map((button, index) => (
        <LinkButton
          key={index}
          {...button}
          className={cn(className, !button.label && 'w-full md:w-full')}
          // className={clsx(className, !button.label && 'w-full md:w-full')}
        />
      ))}
    </>
  );
};

export default LinkButton;