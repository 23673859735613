export const paddingClasses = {
  top: {
    0: 'pt-0',
    2: 'pt-2 md:pt-4',
    4: 'pt-4 md:pt-8',
    8: 'pt-8 md:pt-16',
    16: 'pt-16 md:pt-32',
    24: 'pt-24 md:pt-32',
    32: 'pt-32 md:pt-32',
    40: 'pt-40 md:pt-32',
    48: 'pt-48 md:pt-32',
    52: 'pt-52 md:pt-32',
  },
  bottom: {
    0: 'pb-0',
    2: 'pb-2 md:pb-4',
    4: 'pb-4 md:pb-8',
    8: 'pb-8 md:pb-16',
    16: 'pb-16 md:pb-32',
    24: 'pb-24 md:pb-32',
    32: 'pb-32 md:pb-32',
    40: 'pb-40 md:pb-32',
    48: 'pb-48 md:pb-32',
    52: 'pb-52 md:pb-32',
  },
};

export const paddingClassesModal = {
  top: {
    0: 'pt-0',
    2: 'pt-2 sm:pt-4',
    4: 'pt-4 sm:pt-8',
    8: 'pt-8 sm:pt-8',
    16: 'pt-16 md:pt-16',
    24: 'pt-16 md:pt-16',
    32: 'pt-16 md:pt-16',
    40: 'pt-16 md:pt-16',
    48: 'pt-16 md:pt-16',
    52: 'pt-16 md:pt-16',
  },
  bottom: {
    0: 'pb-0',
    2: 'pb-2 sm:pb-2',
    4: 'pb-4 sm:pb-4',
    8: 'pb-8 sm:pb-8',
    16: 'pb-16 md:pb-8',
    24: 'pb-16 md:pb-8',
    32: 'pb-16 md:pb-8',
    40: 'pb-16 md:pb-8',
    48: 'pb-16 md:pb-8',
    52: 'pb-16 md:pb-8',
  },
};
