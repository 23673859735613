import { ComponentPropsWithoutRef } from 'react';
import { twMerge } from 'tailwind-merge';

const buttonStyles = {
  Primary: 'border-2 border-GREEN-500 bg-DEEPGREEN text-WHITE rounded-md hover:bg-DEEPGREEN-500',
  Secondary: 'border-2 border-PURP bg-DEEPPURPLE text-WHITE rounded-md hover:bg-DEEPPURPLE-500',
  Tertiary: 'text-black hover:opacity-80',
} as const;

export default function Button({
  children,
  className,
  buttonType,
  ...restProps
}: {
  children: React.ReactNode;
  className?: string;
  buttonType?: 'Primary' | 'Secondary' | 'Tertiary';
} & ComponentPropsWithoutRef<'button'>) {
  const baseStyles = 'font-caption text-[11px] transition-colors duration-300 px-5 py-2';
  const buttonClasses = twMerge(baseStyles, buttonStyles[buttonType || 'Primary'], className);

  return (
    <button className={buttonClasses} {...restProps}>
      {children}
    </button>
  );
}